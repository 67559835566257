import { Game1Actions} from "./action";



export interface Game1State {
    playerReady: boolean,
    user: string | null,
    s1: any,
    s2: any,
    s1Player: string | null,
    s2Player: string | null,
    food: any,
    s1Direction: string;
    s2Direction: string;
    s1x: number;
    s1y: number;
    s2x: number;
    s2y: number;
    playerMove: boolean;
    clickMove: boolean;
    s1tailLocationArr: any;
    s2tailLocationArr: any;
    s1eat: boolean;
    s2eat: boolean;

}

const initialState: Game1State = {
    playerReady: false,
    user: null,
    s1: null,
    s2: null,
    s1Player: null,
    s2Player: null,
    food: null,
    s1Direction: 'right',
    s2Direction: 'left',
    s1x: 0,
    s1y: 0,
    s2x: 0,
    s2y: 0,
    playerMove: false,
    clickMove: false,
    s1tailLocationArr: null,
    s2tailLocationArr: null,
    s1eat: false,
    s2eat: false,
}


export function game1Reducer(state: Game1State = initialState, action: Game1Actions): Game1State {
    switch (action.type) {
        case '@@game1/GetS1State':
            return {
                ...state,
                s1: action.state,
                s1Player: action.player,
            }
        case '@@game1/GetS2State':
            return {
                ...state,
                s2: action.state,
                s2Player: action.player,
            }
        case '@@game1/GetFoodState':
            return {
                ...state,
                food: action.state,
            }
        case '@@game1/S1Move':
            return {
                ...state,
                s1Direction: action.direction,
                s1x: action.s1x,
                s1y: action.s1y,
                s2x: action.s2x,
                s2y: action.s2y,
                playerMove: action.playerMove,
            }
        case '@@game1/S2Move':
            return {
                ...state,
                s2Direction: action.direction,
                s1x: action.s1x,
                s1y: action.s1y,
                s2x: action.s2x,
                s2y: action.s2y,
                playerMove: action.playerMove,
            }
        case '@@game1/setUser':
            return {
                ...state,
                user: action.user,
            }
        case '@@game1/setMove':
            return {
                ...state,
                playerMove: action.move
            }
        case '@@game1/setClickMove':
            return {
                ...state,
                clickMove: action.clickMove
            }
        case '@@game1/setPlayerReady':
            return {
                ...state,
                playerReady: action.ready
            }
        case '@@game1/eatFood':
            return {
                ...state,
                s1eat: action.s1eat,
                s2eat: action.s2eat,
            }
        case '@@game1/reset':
            return {
                playerReady: false,
                user: null,
                s1: null,
                s2: null,
                s1Player: null,
                s2Player: null,
                food: null,
                s1Direction: 'right',
                s2Direction: 'left',
                s1x: 0,
                s1y: 0,
                s2x: 0,
                s2y: 0,
                playerMove: false,
                clickMove: false,
                s1tailLocationArr: null,
                s2tailLocationArr: null,
                s1eat: false,
                s2eat: false,

            }

        default:
            return state;
    }
}