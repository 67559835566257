import React from "react";
import { useFormState } from "react-use-form-state";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/auth/actions";
import { RootState } from '../store'
import { useHistory } from "react-router";

export function LoginPage() {
    const [formState, { text, password }] = useFormState({
        username:"",
        password:""
    }, { onReset() { } });
    const dispatch = useDispatch();
    const errorMessage = useSelector((state: RootState) => state.auth.error)
    let history = useHistory()


    return (
        <>
            <div>
                <h1>Login</h1>

                <form onSubmit={event => {
                    event.preventDefault();

                    dispatch(login(formState.values.username, formState.values.password, history))
                    formState.reset();
                }}
                >
                    { errorMessage && <p>{ errorMessage }</p> }
                    <div className=" grow input_margin" >
                    <input placeholder="username" {...text('username')} />
                    </div>
                    <div className=" grow input_margin" >
                    <input placeholder="password" {...password('password')} />
                    </div>
                    <input type="submit" value="submit" />
                </form>
            </div>
        </>
    )
}