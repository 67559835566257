import React, { useEffect } from "react";
import "./search-form.css";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../types";

import { CloseBtn } from "../close-btn/close-btn";
import { useState } from "react";
import {
  createChatroom,
  searchUser,
} from "../../redux/chatBox/action";
import { RootState, store } from "../../store"
import { loadFriends } from "../../redux/friends/action";



export function SearchForm(props: { close: () => void }) {
  let [searchText, setSearchText] = useState(" ");  // 
  let [tab, setTab] = useState("friend");

  let { search_result, rooms } = useSelector((state: RootState) => state.chatroom);
  let { token } = useSelector((state: RootState) => state.auth);
  let { Friend } = useSelector((state: RootState) => state.friend);
  let dispatch = useDispatch();
  // let store = [] as any



//updatae+ pull

  useEffect(() => {
    console.log({ token })
    if (token != null) {

      if (tab === "friend") {
        dispatch(loadFriends(token))

      }
      else { store.dispatch(searchUser(searchText, token) as any) }
    }// eslint-disable-next-line
  }, [searchText, tab])    // this is not working in here 






  // function search() {
  //   setTimeout(() => {
  //     dispatch(
  //       saveSearchResult([
  //         {
  //           user_id: 1,
  //           name: "Alice",
  //         },
  //         {
  //           user_id: 2,
  //           name: "Bob",
  //         },
  //         {
  //           user_id: 3,
  //           name: "Charlie",
  //         },
  //         {
  //           user_id: 20,
  //           name: searchText,
  //         },
  //       ])
  //     );
  //   }, 500);
  // }


  function startChat(user: User) {   // we need to retrieve the the info 

    dispatch(
      createChatroom(user.user_id)    ///THE TYPE!!!!
    );
  }


  return (
    
      <div className="search-user">
        <div className="app-row">
          <div className="title grow">Start a chatroom</div>
          <CloseBtn close={props.close} />
        </div>
        <div className="searchAndFriend">
          <button onClick={() => setTab("search")}>search</button>
          <button onClick={() => setTab("friend")}>friend</button>       {/* push has replaced setPlace in here  */}
        </div>
        {tab === "search" ? <>
          <div className="app-row">
            <input
            onChange={(e) => setSearchText((e.target as HTMLInputElement).value)}
              value={searchText}//the {} tells which is the targeted items for usestate to moniter //or the origina
              onInput={(e) => setSearchText((e.target as HTMLInputElement).value)}// the event that trigger the usestate(oninput)
              placeholder="search by username"
            />
          </div> <h3>Result</h3>
          {search_result.map((user) => (
            <div key={user.user_id} className="app-row ">
              <button
                disabled={rooms.some((room) => room.user_id === user.user_id)}
                className="chat-btn"
                onClick={() => startChat(user)}
              >
                Chat
            </button>
              <div className="grow name">{user.name}</div>
            </div>
          ))}

        </> : <>

            {Friend.map((friend)=>(
               <div key={friend.user_id} className="app-row ">
               <button
                 disabled={rooms.some((room) => room.user_id === friend.user_id)}
                 className="chat-btn"
                 onClick={() => startChat(friend)}
               >
                 Chat
             </button>
               <div className="grow name">{friend.name}</div>
             </div>

            ))}

          </>}

      </div>

  );
}