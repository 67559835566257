import { UserActions, User } from "./action";

export interface UserState {
    UserIds: number[];
    UserById: {
        [id: string]: User
    }
}

const initialState: UserState = {
    UserIds: [],
    UserById: {}
}

export function userReducer(state: UserState = initialState, action: UserActions): UserState {
    switch (action.type) {
        case '@@User/LOADED_FORMS':
            const newUserById: { 
                [id: string]: User
            } = {}
            for (const user of action.Users) {    // extract the id from
                newUserById[user.id] = user;
            }
            return {
                UserIds: action.Users.map(user => user.id),
                UserById: newUserById
            }
        default:
            return state;
    }
}