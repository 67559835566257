import React, { useState } from 'react';
import './App.css'
// import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store';
import { logout } from './redux/auth/actions';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavLink } from 'reactstrap';
// UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
import { Link } from 'react-router-dom'


function AppNavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
    const user = useSelector((state: RootState) => state.auth.user)
    const dispatch = useDispatch();

    const toggle = () => setIsOpen(!isOpen);


    return (
        <div>
            <div className="app-nav-bar">
                <Navbar color="light" light expand="md">
                    <NavbarBrand><Link to="/" className="link-text">𝙃𝙤𝙢𝙚</Link></NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavLink>
                                <Link to="/Introduction" className="link-text">-𝐈𝐧𝐭𝐫𝐨𝐝𝐮𝐜𝐭𝐢𝐨𝐧</Link>
                            </NavLink>
                            <NavLink>
                                <Link to="/AIvsAI" className="link-text">𝐀𝐈 𝐕𝐒 𝐀𝐈</Link>
                            </NavLink>
                            <NavLink>
                                <Link to="/vsAI" className="link-text">𝐁𝐚𝐭𝐭𝐥𝐞 𝐖𝐢𝐭𝐡 𝐀𝐈</Link>
                            </NavLink>
                            {/* <NavLink>
                                <Link to="/game3" className="link-text">𝐋𝐨𝐛𝐛𝐲</Link>
                            </NavLink> */}
                            <NavLink>
                                <Link to="/Blog" className="link-text">𝐁𝐥𝐨𝐠</Link>
                            </NavLink>
                        </Nav>

                        {
                            isAuthenticated ?

                                <Nav navbar>
                                    <NavLink nav caret disabled>
                                        hello again {user?.username}
                                    </NavLink>
                                    <NavLink>
                                        <Link to="/" onClick={() => dispatch(logout())} className="link-text">𝐥𝐨𝐠𝐨𝐮𝐭</Link>
                                    </NavLink>
                                </Nav>

                                :
                                <Nav navbar>

                                    <NavLink>
                                        <Link to="/createUser" className="link-text">𝐍𝐞𝐰 𝐔𝐬𝐞𝐫</Link>
                                    </NavLink>
                                    <NavLink>
                                        <Link to="/login" className="link-text">𝐥𝐨𝐠𝐢𝐧</Link>
                                    </NavLink>
                                </Nav>
                        }
                    </Collapse>
                </Navbar>
            </div>
        </div>

    );
}

export default AppNavBar;



