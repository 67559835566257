import React, { useEffect } from "react";
import "./chat-container.css";
import { useState } from "react";
import { RootState } from "../../store";
import { Chatroom } from "./chatroom"
import { useSelector, useDispatch } from "react-redux";
import { ChatButton } from "./chat-button";
import { SearchForm } from "./search-form"
import { socket } from "../../socket";
import { addChatMessage } from "../../redux/chatBox/action"

export function ChatContainer() {
  let dispatch = useDispatch()
  let rooms = useSelector((state: RootState) => state.chatroom.rooms);
  let [isSearch, setIsSearch] = useState(false);

  //putting here is because this constantlly here 
  useEffect(() => {
    function onchat(data: any) {
      console.log(data.message)
      console.log(data)
      console.log(data.created_at)
      dispatch(addChatMessage(data.sender, [{ 
        created_at: data.created_at,
        message: data.message,
        receiver: data.user_id
      }]))
    }
    socket.on('chat', onchat)   //this proactivly receive message from backend with socket io
    return function () {
      socket.removeListener('chat', onchat)
    }
  }, [dispatch])  //  [dispatch can also refer as empty array]


  // console.log(" rooms",rooms)
  return (

    <div className="chat-container">
      <div className="row ">

        {/* <Provider store={store}>
          <ConnectedRouter history={history}> */}
            {rooms.map((room) => (

              <Chatroom user_id={room.user_id} key={room.user_id} />   // this pass data to the next component via prop , 
            ))}

            {isSearch ? <SearchForm close={() => setIsSearch(false)}  /> : undefined}

          {/* </ConnectedRouter>
        </Provider> */}
      </div>
      {!isSearch ? <ChatButton onClick={() => setIsSearch(true)} /> : undefined}
    </div>

  )
}