import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./BlogPage.css"

import hour from './BlogPageImg/hour.gif'
import day from './BlogPageImg/day.gif'
import week from './BlogPageImg/week.gif'

function BlogPage() {

    return (
        <div className="blog-background">
            <h1 className="blog-big-title">𝓞𝓾𝓻 𝓑𝓵𝓸𝓰</h1>
            <Container>
                <div className="blog-container-all">

                    <Row className="blog-container">
                        <Col sm="5">
                            <div className="blog-AI-container">
                                <img alt="hour" src={hour} />
                            </div>
                        </Col>
                        <Col sm="7">
                            <div className="blog-text-container">
                                <h3 className="blog-title">A week later, the A.I. can alse dodge the enemy</h3>
                                <br />
                                <div className="blog-content">
                                    <div>A.I. learns how to dodge enemy, </div>
                                    <div>and eat food as much as possible. </div>
                                    <div>average length becomes 10</div>
                                </div>
                                <div className="blog-date">date: 30-8-2020</div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="blog-container">
                        <Col sm="5">
                            <div className="blog-AI-container">
                                <img alt="day" src={day} />
                            </div>
                        </Col>
                        <Col sm="7">
                            <div className="blog-text-container">
                                <h3 className="blog-title">After a day, A.I. know how to eat food</h3>
                                <br />
                                <div className="blog-content">
                                    <div>A.I. start to know the position of food. </div>
                                    <div>but sometimes collide itself, </div>
                                    <div>and ignore the position of enemy</div>
                                </div>

                                <div className="blog-date">date: 23-8-2020</div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="blog-container">
                        <Col sm="5">
                            <div className="blog-AI-container">
                                <img alt="week" src={week} />
                            </div>
                        </Col>
                        <Col sm="7">
                            <div className="blog-text-container">
                                <h3 className="blog-title">AI borns a hour, just random moving</h3>
                                <br />
                                <div className="blog-content">
                                    <div>A.I. snakes are random moving to learn different situation, </div>
                                    <div>and the rewards by eating food and colliding with enemy</div>
                                    <div>each move can change the network bias and weight.</div>
                                </div>

                                <div className="blog-date">date: 23-8-2020</div>
                            </div>
                        </Col>
                    </Row>

                </div>
            </Container>
        </div >
    )
}

export default BlogPage;