import { ThunkDispatch } from "../../store";
import {User} from "../../types"




export function loadFriends(token :string) {
    return async function (dispatch: ThunkDispatch) {
        console.log("loadFriends")
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/friends`, {
            method:'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    let store = await res.json()
      console.log(store)
      dispatch( saveFriend(store))
    }

    
}


export function saveFriend(result:User[]){
    return{
        type:"@@User/saveFriend" as "@@User/saveFriend",
        result,
    }

}
// user_id:number,name:string
export function addFriend(user_id:number,name:string){
   
    return{
        type:"@@User/addFriend" as "@@User/addFriend",
        user_id,
        name
    }

}

export function removeFriend(user_id:number){
    return{
        type:"@@User/removeFriend" as "@@User/removeFriend",
        user_id,
    }

}







export type friendActions = ReturnType<typeof saveFriend>|ReturnType<typeof addFriend>|ReturnType<typeof removeFriend>;