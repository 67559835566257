import React, { useEffect } from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store';
import { CreateUserPage } from './pages/createUserPage/CreateUserPage';
import { restoreLogin } from './redux/auth/actions';
import AppNavBar from './navBar';
import { Page404 } from './pages/404Page';
import { ChatContainer } from "./components/chat/chat-container"
// import { ConnectedRouter } from 'connected-react-router';
import { HomePage } from './pages/HomePage/HomePage';

// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter as Router } from 'react-router-dom'

import IntroductionPage from './pages/IntroductionPage/IntroductionPage';
import AIvsAIPage from './pages/AIvsAIPage/AIvsAIPage';
import VsAiPage from './pages/vsAIPage/vsAiPage';
import BlogPage from './pages/BlogPage/BlogPage';



function App() {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(restoreLogin())
  }, [dispatch])

  return (
    
    <Router>
      {/* <ConnectedRouter history={history}> */}
      <div className="App">
        {isAuthenticated === null ? <div>Loading...</div> :
          <>
              <AppNavBar />
              <Switch>

                <Route path="/createUser"><CreateUserPage /></Route>
                <Route path="/login"><LoginPage /></Route>
                <Route path="/Introduction"><IntroductionPage /></Route>
                <Route path="/AIvsAI"><AIvsAIPage /></Route>
                <Route path="/vsAI"><VsAiPage /></Route>
                <Route path="/Blog"><BlogPage /></Route>

                {/* <Route path="/game3"><Game3Page /></Route> */}
                {/* <PrivateRoute path="/game1"><Game1RoomPage /></PrivateRoute> */}
                <Route path="/" exact><HomePage /></Route>
                <Route><Page404 /></Route>
              </Switch>

              <ChatContainer />
            
          </>
        }
      </div>
    {/* </ConnectedRouter> */}
    </Router>
  );
}

export default App;
