import { Game3Actions } from "./action";



export interface Game3State {
    user: string | null;
    player1: boolean;
    room: string | null;
    s1x: number | null;
    s1y: number | null;
    s1TailArr: any;
    s2x: number | null;
    s2y: number | null;
    s2TailArr: any;
    food: any;
    s1Direction: string | null;
    s2Direction: string | null;

    playerReady: boolean;
}

const initialState: Game3State = {
    user: null,
    player1: false,
    room: null,
    s1x: null,
    s1y: null,
    s1TailArr: [],
    s2x: null,
    s2y: null,
    s2TailArr: [],
    food: null,
    s1Direction: 'right',
    s2Direction: 'left',

    playerReady: false,
}


export function game3Reducer(state: Game3State = initialState, action: Game3Actions): Game3State {
    switch (action.type) {

        case '@@game3/setPlayerReady':
            return {
                ...state,
                playerReady: action.ready
            }
        
        //game 

        case '@@game3/player1Start':
            return {
                ...state,
                s1x: action.s1x,
                s1y: action.s1y,
                player1: action.player1,
            }
        case '@@game3/player2Start':
            return {
                ...state,
                s2x: action.s2x,
                s2y: action.s2y,
            }
        case '@@game3/joinRoom':
            return {
                ...state,
                room: action.room,
            }
        case '@@game3/autoMove':
            return {
                ...state,
                s1x: action.s1x,
                s1y: action.s1y,
                s2x: action.s2x,
                s2y: action.s2y,
                s1TailArr: action.s1TailArr,
                s2TailArr: action.s2TailArr,
                s1Direction: action.s1Direction,
                s2Direction: action.s2Direction,
            }
        case '@@game3/setUser':
            return {
                ...state,
                user: action.user,
            }
        case '@@game3/setFood':
            return {
                ...state,
                food: action.food
            }
        case '@@game3/reset':
            return {
                ...state,
                user: null,
                player1: false,
                s1x: null,
                s1y: null,
                s1TailArr: [],
                s2x: null,
                s2y: null,
                s2TailArr: [],
                food: null,
                s1Direction: 'right',
                s2Direction: 'left',

                playerReady: false,
            }

        default:
            return state;
    }
}