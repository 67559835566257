import React from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "../store";
import "./HomePage.css";
import HomePageAI from "./HomePageAI";
import { Row, Col, Container, Button } from "reactstrap";
import { useHistory } from "react-router-dom";


export function HomePage() {
    // const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
    const history = useHistory();


    return (
        <>
            <div className="HomePage-background">
                <Container>
                    <Row>
                        <Col sm="8" className="Top-container">
                            <h1 className="tittleText">Challedge our</h1>
                            <h1 className="tittleText">A.I. Snake</h1>
                            <h1 className="tittleText">and learn how they play</h1>
                            <br />
                            <h5 className="opacityText">Snake is programmed by double-deep-q algorithm. </h5>
                            <h5 className="opacityText">All you need to do is eating all the food and kill the A.I.</h5>

                            <h5 className="opacityText">You can join the battle directly or just watch the plays by the A.I.s</h5>
                            <Row>
                                <Col sm="4">
                                    <Row>
                                        <Button color="info" className="button box-shadow" onClick={() => {
                                            history.push('/createUser')
                                        }}><div className="button-text">Create Account</div></Button>
                                    </Row>
                                </Col>
                                <Col sm="4">
                                    <Row>
                                        <Button color="secondary" className="button box-shadow" onClick={() => {
                                            history.push('/Introduction')
                                        }}><div className="button-text">Introduction</div></Button>{' '}
                                    </Row>
                                </Col>
                                <Col sm="4" />
                            </Row>
                        </Col>
                        <Col sm="4" className="Top-container">
                            <div className="game box-shadow">
                                <HomePageAI />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <div className="bottom-container">
                    <h1>Our Game</h1>
                    <div className='opacity-text'>Battle other players in real-time with our game</div>

                    <div className="home-page-game-container">
                        <div>
                            <div className="home-page-game-text">Snake Game</div>
                            <Link to="/game1">

                                <img src={logo} alt="refresh" className="home-page-game" />
                            </Link>
                        </div>
                        <div>
                            <div className="home-page-game-text">coming soon</div>
                            <img src={comingSoonLogo} alt="refresh" className="home-page-game-soon" />
                        </div>
                        <div>
                            <div className="home-page-game-text">coming soon</div>
                            <img src={comingSoonLogo} alt="refresh" className="home-page-game-soon" />
                        </div>
                        <div>
                            <div className="home-page-game-text">coming soon</div>
                            <img src={comingSoonLogo} alt="refresh" className="home-page-game-soon" />
                        </div>
                    </div>
                </div> */}


                <div>

                </div>
            </div>
        </>
    )
}