import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import { connectRouter, routerMiddleware, RouterState, RouterAction } from "connected-react-router"
import { createBrowserHistory } from "history";
import thunk, { ThunkDispatch as OldThunkDispatch } from 'redux-thunk';
import { AuthState, authReducer } from "./redux/auth/reducer";
import { AuthActions } from "./redux/auth/actions";
import { UserState, userReducer } from "./redux/User/reducer";
import { UserActions } from "./redux/User/action";
import { chatRoomState } from "./types";
import { chatRoomAction } from "./redux/chatBox/action"
import { chatRoomReducer } from "./redux/chatBox/reducer"
import { friendActions } from "./redux/friends/action"
import { friendReducer, FriendState } from "./redux/friends/reducer"

import { Game1State, game1Reducer } from "./redux/Game1/reducer";
import { Game1Actions } from "./redux/Game1/action";
import { Game3State, game3Reducer } from "./redux/game3/reducer";



export const history = createBrowserHistory();

export interface RootState {
    auth: AuthState,
    user: UserState,
    chatroom: chatRoomState,
    friend: FriendState,
    game1: Game1State,
    game3: Game3State,
    router: RouterState,
}

export type RootActions = RouterAction | UserActions | AuthActions | chatRoomAction | friendActions | Game1Actions;


const reducers = combineReducers<RootState>({
    auth: authReducer,
    user: userReducer,
    chatroom: chatRoomReducer,
    friend: friendReducer,
    game1: game1Reducer,
    game3: game3Reducer,
    router: connectRouter(history)
})

declare global {
    /* tslint:disable:interface-name */
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    }
}

export type ThunkDispatch = OldThunkDispatch<RootState, null, RootActions>

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  
export const store = createStore<RootState, RootActions, {}, {}>(
    reducers, composeEnhancers(
        applyMiddleware(thunk),
        applyMiddleware(routerMiddleware(history))
    )
)


declare global{
    //    tslint:disable:interface-name 
        interface Window{
           __REDUX_DEVTOOLS_EXTENSION__:any
        }
     }
    
export default createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      );

