import React from "react";
// import {useDispatch } from 'react-redux';
import { useFormState } from "react-use-form-state";
import "./CreateUserPage.css"
import { Button, Container, Row, Col } from "reactstrap";
// import { push } from "connected-react-router";
// import {  replace, push } from "connected-react-router";
import { useHistory } from 'react-router-dom';

export function CreateUserPage() {
    const history = useHistory();
    const [formState, { text, password }] = useFormState({
        username: "",
        password: ""
    }, { onReset() { } });



    return (
        <>
            <div>
                <h1 className="title">Create User</h1>
                <Container className="create-user-container" >
                    <Row>
                        <Col sm="10">
                            <form onSubmit={async event => {
                                event.preventDefault();

                                const req = await fetch(`${process.env.REACT_APP_BACKEND_URL}/createUser`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(formState.values)
                                })
                                console.log(req)

                                let res = await req.json();
                                formState.reset();
                                console.log(res)
                                // dispatch(push("/login"))//       * not working
                                history.push("/login")
                            }} className="form-container" >

                                <input placeholder="username" {...text('username')} className="form-input" />

                                <input placeholder="password" {...password('password')} className="form-input" />

                                <Button type="submit" className="submit-button" color="info">Submit</Button>
                            </form>

                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}