import { friendActions } from "./action";
import { User } from "../../types"
export interface FriendState {
    Friend: User[]

    // User: {name:string,
    //     user_id:number}[],

}

const initialState: FriendState = {
    Friend: []
}

export function friendReducer(state: FriendState = initialState, action: friendActions): FriendState {
    switch (action.type) {
        // eslint-disable-next-line
        case "@@User/saveFriend": {
            return {
                ...state,
                Friend: action.result,
            }
        };
        case "@@User/addFriend": {
            let friendsInState = state.Friend

            let friend = state.Friend.find(user => user.user_id === action.user_id)

            // let friendList =friendsInState.find(({user_id,name})=>{  //we want to find whether there is a friend in the fri
            //     return {user_id,name}==friendsInState

            // })
            if (!friend) {
                friend = {
                    user_id: action.user_id,
                    name: action.name
                }
                console.log("update", action)

                friendsInState = [                 //refer slice
                    ...friendsInState,
                    friend
                ]


            }
            return {
                ...state,
                Friend: friendsInState
            }

        };


        case "@@User/removeFriend": {
            let friendsInState = state.Friend
            console.log("remove")

            return {
                ...state,
                Friend: friendsInState.filter((userID) => userID.user_id !== action.user_id)  //the key (state ) must match the types in the types (also need to ask what is the difference between type and interface) 
            }

        }
        default:
            return state;
    }
}


