import React, { useState } from "react";
import p5Types from "p5";
import Sketch from "react-p5";
import { Snake } from "./snake";
import "./HomePage.css";


var scl = 20;
var width = 300;
var height = 300;
var cols = width / scl
var rows = height / scl

var s1: any;
var s2: any;
var food: any;
var firstTime: boolean;

function HomePageAI() {



    const [eatFood, setEatFood] = useState(false);
    const [eatTotal, setEatTotal] = useState(0);


    const setup = async (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);

        setEatFood(false);
        setEatTotal(0);

        s1 = new Snake(0, 0, 1, 0, 115, 73, 172, "RIGHT")
        s2 = new Snake(height - scl, height - scl, -1, 0, 135, 13, 16, "LEFT")

        pickLocation();

        await firstTimeChooseAction();
        p5.frameRate(20)
    }


    const draw = async (p5: p5Types) => {
        if (firstTime) {
            // if(notDoingChooseAction){

            s1.closer = false;
            s2.closer = false;

            s1.update();
            s2.update();

            checkS1S2Death();
            await chooseAction()
            // p5.background(s1.colorB, s1.colorG, s1.colorR)
            p5.background('#FFB8DB')
            showBackground(p5)
            showFood(p5, food)
            show(p5)
            // }
        }

    }

    function showBackground(p5: p5Types) {
        p5.noStroke()
        for (let i = 0; i < width / scl; i++) {
            for (let j = 0; j < height / scl; j++) {
                p5.fill('#e0e0e2')
                p5.rect(i * scl + 1.5, j * scl + 1.5, 17, 17, 10)
            }
        }
    }

    function dist(x1: number, y1: number, x2: number, y2: number) {
        let xdiff = x1 - x2
        let ydiff = y1 - y2

        return Math.sqrt(Math.pow(xdiff, 2) + Math.pow(ydiff, 2))
    }

    function checkS1S2Death() {

        //撞人地
        var s2tailArr = s2.tailLocation();// eslint-disable-next-line
        if (s2tailArr.length) {
            for (let i = 0; i < s2tailArr.length; i++) {
                var d = dist(s1.x, s1.y, s2tailArr[i].x, s2tailArr[i].y);
                if (d < 1) {
                    s1.done = true;
                    reset()
                }
            }
        }

        var s1tailArr = s1.tailLocation();// eslint-disable-next-line
        if (s1tailArr.length) {
            for (let i = 0; i < s1tailArr.length; i++) {// eslint-disable-next-line
                var d = dist(s2.x, s2.y, s1tailArr[i].x, s1tailArr[i].y);
                if (d < 1) {
                    s2.done = true;
                    reset()
                }
            }
        }

        // 太家撞大家
        // eslint-disable-next-line
        var d = dist(s1.x, s1.y, s2.x, s2.y);// eslint-disable-next-line
        if (d < 1) {
            s1.done = true
            s2.done = true
            reset()
        }

        //撞自己
        s1.done = s1.checkDeath();
        s2.done = s2.checkDeath();
        if (s1.done) {
            reset()
        }
        if (s2.done) {
            reset()
        }
    }



    function pickLocation() {
        const allLocation = mapArray();
        let randomX = Math.floor(Math.random() * cols)
        let randomY = Math.floor(Math.random() * rows)
        do {
            randomX = Math.floor(Math.random() * cols)
            randomY = Math.floor(Math.random() * rows)// eslint-disable-next-line
        } while (allLocation[randomX + randomY * (height / scl)] != 0)
        food = { x: randomX * scl, y: randomY * scl }

    }


    function show(p5: p5Types) {
        s1.show(p5)
        s2.show(p5)
    }





    function showFood(p5: p5Types, food: any) {
        if (food) {
            heart(p5, food.x + 10, food.y + 2, 20)
        }
    }

    const heart = (p5: p5Types, x: number, y: number, size: number) => {
        p5.fill('#fb1515')
        p5.beginShape();
        p5.vertex(x, y);
        p5.bezierVertex(x - size / 2, y - size / 3, x - size, y + size / 3, x, y + size);
        p5.bezierVertex(x + size, y + size / 3, x + size / 2, y - size / 3, x, y);
        p5.endShape();
        p5.fill(255)
    }



    function getState(snake: Snake) {
        let rival = s1
        if (snake === s1) {
            rival = s2
        }
        if (snake === s2) {
            rival = s1
        }
        var allLocation = mapArray();
        var newArray = [];
        while (allLocation.length) newArray.push(allLocation.splice(0, width / scl));
        var rowOfSnake = snake.y / scl;
        var colOfSnake = snake.x / scl;
        var maximumCol = width / scl - 1
        var maximumRow = height / scl - 1

        var left = 0
        var right = 0
        var up = 0
        var down = 0


        for (let i = colOfSnake - 1; i >= 0; i--) {
            if (newArray[rowOfSnake][i] !== 0) {
                left = colOfSnake - i
                break;
            }
        }
        if (left === 0) {
            for (let i = maximumCol; i >= colOfSnake; i--) {
                if (newArray[rowOfSnake][i] !== 0) {
                    left = (maximumCol - i) + (colOfSnake) + 1
                    break;
                }
            }
        }

        for (let i = colOfSnake + 1; i <= maximumCol; i++) {
            if (newArray[rowOfSnake][i] !== 0) {
                right = i - colOfSnake
                break;
            }
        }
        if (right === 0) {
            for (let i = 0; i <= colOfSnake; i++) {
                if (newArray[rowOfSnake][i] !== 0) {
                    right = (maximumCol - colOfSnake) + i + 1
                    break;
                }
            }
        }

        for (let i = rowOfSnake - 1; i >= 0; i--) {
            if (newArray[i][colOfSnake] !== 0) {
                up = rowOfSnake - i
                break;
            }
        }

        if (up === 0) {
            for (let i = maximumRow; i >= rowOfSnake; i--) {
                if (newArray[i][colOfSnake] !== 0) {
                    up = (maximumRow - i) + (rowOfSnake) + 1
                    break;
                }
            }
        }

        for (let i = rowOfSnake + 1; i <= maximumRow; i++) {
            if (newArray[i][colOfSnake] !== 0) {
                down = i - rowOfSnake
                break;
            }
        }
        if (down === 0) {
            for (let i = 0; i <= rowOfSnake; i++) {
                if (newArray[i][colOfSnake] !== 0) {
                    down = (maximumRow - rowOfSnake) + i + 1
                    break;
                }
            }
        }
        let a = Math.abs(snake.x - food.x);
        let b = Math.min(Math.abs(snake.x + (width - food.x)), Math.abs((width - snake.x) + food.x));
        let c = Math.abs(snake.y - food.y);
        let d = Math.min(Math.abs(snake.y + (height - food.y)), Math.abs((height - snake.y) + food.y));

        let xDiff = Math.min(a, b)
        if (xDiff === a) {

            a = -(snake.x - food.x)

        } else {
            if (snake.x > food.x) {
                a = (((width) - snake.x) + food.x)
            } else {
                a = -(snake.x + ((width) - food.x))
            }
        }
        let yDiff = Math.min(c, d)
        if (yDiff === c) {

            c = -(snake.y - food.y)

        } else {
            if (snake.y > food.y) {
                c = (((height) - snake.y) + food.y)
            } else {
                c = -(snake.y + ((height) - food.y))
            }
        }

        const state: any[] = [a, c, up, down, left, right, snake.x - rival.x, snake.y - rival.y];

        return state;
    }


    function mapArray() {
        var s1tailArr = s1.tailLocation();
        var s2tailArr = s2.tailLocation();
        var outputSnakeArr = []

        for (let i = 0; i < height / scl * width / scl; i++) {
            outputSnakeArr[i] = 0
        }

        for (let i = 0; i < s1tailArr.length; i++) {
            outputSnakeArr[Math.floor(s1tailArr[i].x / scl) + Math.floor(s1tailArr[i].y / scl) * height / scl] = 1
        }

        outputSnakeArr[Math.floor(s1.x / scl) + Math.floor(s1.y / scl) * height / scl] = 2

        for (let i = 0; i < s2tailArr.length; i++) {
            outputSnakeArr[Math.floor(s2tailArr[i].x / scl) + Math.floor(s2tailArr[i].y / scl) * height / scl] = -1
        }

        outputSnakeArr[Math.floor(s2.x / scl) + Math.floor(s2.y / scl) * height / scl] = -2

        return outputSnakeArr;
    }

    function step(snake: Snake, action: number) {
        if (action === 0 && snake.direction !== "LEFT") {
            snake.dir(1, 0);
            snake.direction = "RIGHT";
        } else if (action === 1 && snake.direction !== "RIGHT") {
            snake.dir(-1, 0);
            snake.direction = "LEFT";

        } else if (action === 2 && snake.direction !== "UP") {
            snake.dir(0, 1);
            snake.direction = "DOWN";

        } else if (action === 3 && snake.direction !== "DOWN") {
            snake.dir(0, -1);
            snake.direction = "UP";

        }
    }


    function getRewardAndDone(snake: Snake) {
        // var reward = -0.3
        var reward = 0

        if (snake.eat(food)) {
            reward += 25
            setEatFood(true)
        }
        if (snake.closer) {
            reward += 0.3
        }
        if (!snake.closer) {
            reward -= 0.15
        }
        if (snake.done) {
            reward -= 50
        }


        var prevent;
        if (snake.tail.length !== 0) {
            if (snake.receivedAction === 0) {
                prevent = "LEFT"
            } else if (snake.receivedAction === 1) {
                prevent = "RIGHT"
            } else if (snake.receivedAction === 2) {
                prevent = "UP"
            } else {
                prevent = "DOWN"
            }
            if (snake.direction === prevent) {
                reward -= 1
            }
        }
        snake.score += reward

        return [reward, snake.done]
    }

    async function reset() {
        s1 = null
        s2 = null
        food = null

        setEatFood(false);
        setEatTotal(0);

        s1 = new Snake(0, 0, 1, 0, 115, 73, 172, "RIGHT")
        s2 = new Snake(height - scl, height - scl, -1, 0, 135, 13, 16, "LEFT")

        pickLocation();

        await firstTimeChooseAction();
    }


    // to backend

    async function firstTimeChooseAction() {
        let observation = getState(s1);


        const s1fetchRes = await fetch(`${process.env.REACT_APP_BACKEND_URL}/s1firstTimeChooseAction`, {

            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({ state: observation }),
        })
        const s1result = await s1fetchRes.text()
        step(s1, parseInt(JSON.parse(s1result).action))

        observation = getState(s2);


        const s2fetchRes = await fetch(`${process.env.REACT_APP_BACKEND_URL}/s2firstTimeChooseAction`, {

            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({ state: observation }),
        })
        const s2result = await s2fetchRes.text()
        step(s2, parseInt(JSON.parse(s2result).action))
        firstTime = true;

    }

    async function chooseAction() {
        //s1 state
        let rewardAndDone = getRewardAndDone(s1)
        if (eatFood) {
            pickLocation();
            setEatFood(false)
            setEatTotal(eatTotal + 1)
        }
        let observation = getState(s1);
        observation = [observation, rewardAndDone[0], rewardAndDone[1]]

        //s1 request action
        const s1fetchRes = await fetch(`${process.env.REACT_APP_BACKEND_URL}/s1chooseAction`, {

            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({ state: observation }),
        })
        const s1result = await s1fetchRes.text();

        //s2 state
        rewardAndDone = getRewardAndDone(s2)
        if (eatFood) {
            pickLocation();
            setEatFood(false)
            setEatTotal(eatTotal + 1)
        }
        observation = getState(s2);
        observation = [observation, rewardAndDone[0], rewardAndDone[1]]

        //s2 request action
        const s2fetchRes = await fetch(`${process.env.REACT_APP_BACKEND_URL}/s2chooseAction`, {

            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({ state: observation }),
        })
        const s2result = await s2fetchRes.text();

        // front end





        step(s1, parseInt(JSON.parse(s1result).action))
        s1.receivedAction = parseInt(JSON.parse(s1result).action);
        step(s2, parseInt(JSON.parse(s2result).action))
        s2.receivedAction = parseInt(JSON.parse(s2result).action);
    }



    return (
        <Sketch setup={setup} draw={draw} />
    )
}

export default HomePageAI;