import p5Types from "p5";

var scl = 20;
var width = 300;
var height = 300;

export class Snake {
    x: number
    y: number
    xspeed: number
    yspeed: number
    colorR: number
    colorG: number
    colorB: number
    total: any
    tail: any
    tailLocationArr: any
    direction: string
    closer: boolean
    done: boolean
    receivedAction: number | null
    score: number
    color: number
    colorArr: any[]


    constructor(x: number, y: number, xspeed: number, yspeed: number, colorR: number, colorG: number, colorB: number, direction: string) {
        this.x = x
        this.y = y
        this.xspeed = xspeed
        this.yspeed = yspeed
        this.colorR = colorR
        this.colorG = colorG
        this.colorB = colorB
        this.total = 0;
        this.tail = [];
        this.tailLocationArr = [];
        this.direction = direction;
        this.closer = false;
        this.done = false
        this.receivedAction = null
        this.score = 0;
        this.color = 0
        this.colorArr = [[212, 40, 40], [212, 126, 40], [222, 222, 43], [43, 222, 55], [43, 222, 204], [43, 73, 222], [109, 43, 222]]
        // this.total = 5;
        // this.total = 1;
        // this.tail = [createVector(0, 0), createVector(0, 0), createVector(0, 0), createVector(0, 0), createVector(0, 0)];
    }

    dist(x1: number, y1: number, x2: number, y2: number) {
        // console.log(x1 + "   "  + x2 + "   "  + y1 + "   "  + y2)
        let xdiff = x1 - x2
        let ydiff = y1 - y2

        return Math.sqrt(Math.pow(xdiff, 2) + Math.pow(ydiff, 2))
    }

    eat(food: any) {
        if (this.x === food.x && this.y === food.y) {
            this.total++
            return true
        } else {
            return false
        }
    }
    dir(x: number, y: number) {
        this.xspeed = x;
        this.yspeed = y
    }

    death() {
        for (var i = 0; i < this.tail.length; i++) {
            var pos = this.tail[i];
            var d = this.dist(this.x, this.y, pos.x, pos.y);
            if (d < 1) {
                this.total = 0;
                this.tail = [];
                this.tailLocationArr = [];
            }
        }
    }

    tailLocation() {
        for (var i = 0; i < this.tail.length; i++) {
            var pos = this.tail[i];
            this.tailLocationArr[i] = pos;

        }
        return this.tailLocationArr
    }

    update() {
        if (this.total === this.tail.length) {
            for (var i = 0; i < this.tail.length - 1; i++) {
                this.tail[i] = this.tail[i + 1];
            }
        }
        this.tail[this.total - 1] = { x: this.x, y: this.y }
        this.x = this.x + this.xspeed * 20;
        this.y = this.y + this.yspeed * 20;

        if (this.x > width - scl) {
            this.x = 0
        }
        if (this.y > height - scl) {
            this.y = 0
        }
        if (this.x < 0) {
            this.x = width - scl
        }
        if (this.y < 0) {
            this.y = height - scl
        }
    }


    show(p5: p5Types) {
        p5.fill(this.colorR, this.colorG, this.colorB)
        if (this.tail.length) {
            for (var i = 0; i < this.tail.length; i++) {
                p5.fill(this.colorR + 5 * (this.tail.length - i), this.colorG + 5 * (this.tail.length - i), this.colorB + 5 * (this.tail.length - i))
                p5.noStroke();
                p5.rect(this.tail[i].x, this.tail[i].y, 20, 20)
            }
        }

        p5.rect(this.x, this.y, 20, 20)
        this.turn(p5, this.direction, this.x, this.y)
        // this.changeColor()
    }

    turn(p5: p5Types, direction: any, x: any, y: any) {
        p5.fill(60, 0, 0)
        if (direction === 'RIGHT') {
            p5.rect(x + 12, y + 3, 5, 3)
            p5.rect(x + 12, y + 14, 5, 3)
        }
        if (direction === 'LEFT') {
            p5.rect(x + 3, y + 3, 5, 3)
            p5.rect(x + 3, y + 14, 5, 3)
        }
        if (direction === 'UP') {
            p5.rect(x + 3, y + 2, 3, 5)
            p5.rect(x + 14, y + 2, 3, 5)
        }
        if (direction === 'DOWN') {
            p5.rect(x + 3, y + 13, 3, 5)
            p5.rect(x + 14, y + 13, 3, 5)
        }

        p5.fill(this.colorR, this.colorG, this.colorB)
    }

    changeColor() {
        if (this.color === 6) {
            this.color = 0
        } else {
            this.color += 1
        }
        this.colorR = this.colorArr[this.color][0]
        this.colorG = this.colorArr[this.color][1]
        this.colorB = this.colorArr[this.color][2]
    }

    checkDeath() {
        if (this.tail.length) {
            for (let i = 0; i < this.tail.length; i++) {
                var pos = this.tail[i];
                var d = this.dist(this.x, this.y, pos.x, pos.y);
                if (d < 1) {
                    return true;
                }
            }
        }
        return false;
    }
}
