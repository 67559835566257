import { initState, chatRoomState, Chatroom } from "../../types";
import { chatRoomAction } from "./action";
// import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";
console.log("try")

export function chatRoomReducer(state: chatRoomState = initState, action: chatRoomAction): chatRoomState {
  switch (action.type) {
    case "saveSearchResult":
      return {
        ...state,
        search_result: action.result, // send it back to search_form using rootstate from store
      };

  //     case "addChatMessage": {
  //       console.log("addCheckMessage",action,state)
  //       let rooms = state.rooms
  //       let room = rooms.find((room) => {
  //         return room.user_id === action.room_id
  //       })
  // // console.log("room.messages",room.messages)
  
  //       if(!room){    //if cant find this room
  //         room={
  //           user_id:action.room_id,  
  //           messages:action.messages
  //         }
  //         rooms.push(room)  // this just modify , no renew
  //       }
  //       else{
  //          //cant push becaue it might not be recognised and cant updated on realtime
          
  //          room.messages=[
  //           ...room.messages,
  //           ...action.messages   ] 
  //              // receive a bunch of messageS
  //       }
  
  //       return {                       // returng a 
  //         ...state,
  //         rooms: [...rooms],     // this is needed because this will renew the state everytime
  //       };
  //     }
 

    case "addChatMessage": {
      console.log("addCheckMessage", action, state)
      let rooms = state.rooms  //previous Room
      let room = rooms.find((room) => {    // to find whether there is such user in state
        return room.user_id === action.room_id
      })
      if (!room) {    //no such room in the local state  

        room = {
          user_id: action.room_id,
          messages: action.messages
        }
        rooms = [...rooms, room]  // this just modify , no renew
      }
            else {
        //cant push becaue it might not be recognised and cant updated on realtime

        let newRoom :Chatroom= {
          user_id: room.user_id,
          messages:[...room.messages, // this replace the messages in room
            ...action.messages]
          // messages:room.messages.concat( action.messages)
                 // unlike the above , this is an  array and cant be "put " into the new state , 
          
          
          //merge two arrays
        };
       
        rooms = rooms.map(oldRoom => {     // because of the immuncompulity , we have to "copy " from the state to operate
          if (oldRoom.user_id === newRoom.user_id) {
            return newRoom
          } else {
            return oldRoom
          }
        });
      }
      return {                       // returng a 
        ...state,
        rooms      // this is needed because this will renew the state everytime      if not push        [...rooms]
        }
    }




    case "createChatroom": {

      let rooms = state.rooms

      if (!rooms.find((room) => {
        return room.user_id === action.user_id
      })) {
        rooms = [...rooms, {
          user_id: action.user_id,
          messages: []
        }]
      }
      return {
        ...state,
        rooms: rooms,
      };
    }
    //if there is a room insert the message 
    case "closeChatroom":
      return {
        ...state,
        rooms: state.rooms.filter((room) => room.user_id !== action.user_id),
      };
    default:
      return state;
  }
}
