import React, { useState } from "react";
import p5Types from "p5";
import Sketch from "react-p5";
import { Snake } from "./snake";
import './vsAIPage.css'
import { Button, Container, Row, Col, Progress } from "reactstrap";


const scl = 20;
const width = 300;
const height = 300;
const cols = width / scl;
const rows = height / scl;

let s1: any;
let s2: any;
let food: any;
let firstTime: boolean;

let press = false

let s1Eat = 0;
let s2Eat = 0;

let winner = "";
let round = 0;
let playerWin = 0;
let AiWin = 0;
let evened = 0;

let frameRate = 13;



function VsAiPage() {


    const [eatFood, setEatFood] = useState(false);
    const [eatTotal, setEatTotal] = useState(0);

    const [first, setFirst] = useState(true)
    const [gameEnd, setGameEnd] = useState(true)
    const [start, setStart] = useState(false)


    const setup = async (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(width, height).parent(canvasParentRef);

        setEatFood(false);
        setEatTotal(0);

        s1 = new Snake(0, 0, 1, 0, 115, 73, 172, "RIGHT")
        s2 = new Snake(height - scl, height - scl, -1, 0, 135, 13, 16, "LEFT")

        pickLocation();

        await firstTimeChooseAction();
        p5.frameRate(frameRate)
    }


    const draw = async (p5: p5Types) => {
        if (start) {
            setGameEnd(false)
            if (firstTime) {

                s1.closer = false;
                s2.closer = false;

                s1.update();
                s2.update();

                checkS1S2Death();
                await chooseAction()
                if (s2.eat(food)) {
                    setEatFood(true)
                    s2Eat++
                }
                // p5.background(s1.colorB, s1.colorG, s1.colorR)
                p5.background('#FFB8DB')

                showBackground(p5)
                showFood(p5, food)
                show(p5)
                press = false
                if (s2.total > 11) {
                    s2.changeColor()
                }
            }
        } else {
            p5.background('#FFB8DB')
            showBackground(p5)
            show(p5)
        }

    }

    function showBackground(p5: p5Types) {
        p5.noStroke()
        for (let i = 0; i < width / scl; i++) {
            for (let j = 0; j < height / scl; j++) {
                // p5.fill('#e0e0e2')
                p5.fill(240)
                p5.rect(i * scl + 1.5, j * scl + 1.5, 17, 17, 10)
            }
        }
    }

    function dist(x1: number, y1: number, x2: number, y2: number) {
        let xdiff = x1 - x2
        let ydiff = y1 - y2

        return Math.sqrt(Math.pow(xdiff, 2) + Math.pow(ydiff, 2))
    }

    function checkS1S2Death() {
        //撞自己
        s1.done = s1.checkDeath();
        s2.done = s2.checkDeath();
        //撞人地
        var s2tailArr = s2.tailLocation();// eslint-disable-next-line
        for (var i = 0; i < s2tailArr.length; i++) {
            var d = dist(s1.x, s1.y, s2tailArr[i].x, s2tailArr[i].y);
            if (d < 1) {
                s1.done = true;
            }
        }

        var s1tailArr = s1.tailLocation();// eslint-disable-next-line
        for (var i = 0; i < s1tailArr.length; i++) {// eslint-disable-next-line
            var d = dist(s2.x, s2.y, s1tailArr[i].x, s1tailArr[i].y);
            if (d < 1) {
                s2.done = true;
            }
        }
        // 太家撞大家
        // eslint-disable-next-line
        var d = dist(s1.x, s1.y, s2.x, s2.y);// eslint-disable-next-line
        if (d < 1) {
            s1.done = true
            s2.done = true
        }
    }


    function pickLocation() {
        const allLocation = mapArray();
        let randomX = Math.floor(Math.random() * cols)
        let randomY = Math.floor(Math.random() * rows)
        do {
            randomX = Math.floor(Math.random() * cols)
            randomY = Math.floor(Math.random() * rows)// eslint-disable-next-line
        } while (allLocation[randomX + randomY * (height / scl)] !== 0)
        food = { x: randomX * scl, y: randomY * scl }

    }

    function show(p5: p5Types) {
        s2.show(p5)
        s1.show(p5)
    }





    function showFood(p5: p5Types, food: any) {
        if (food) {
            heart(p5, food.x + 10, food.y + 2, scl)
        }
    }

    const heart = (p5: p5Types, x: number, y: number, size: number) => {
        p5.fill('#fb1515')
        p5.beginShape();
        p5.vertex(x, y);
        p5.bezierVertex(x - size / 2, y - size / 3, x - size, y + size / 3, x, y + size);
        p5.bezierVertex(x + size, y + size / 3, x + size / 2, y - size / 3, x, y);
        p5.endShape();
        p5.fill(255)
    }

    function getState(snake: Snake) {
        let rival = s1
        if (snake === s1) {
            rival = s2
        }
        if (snake === s2) {
            rival = s1
        }
        var allLocation = mapArray();
        var newArray = [];
        while (allLocation.length) newArray.push(allLocation.splice(0, width / scl));
        var rowOfSnake = snake.y / scl;
        var colOfSnake = snake.x / scl;
        var maximumCol = width / scl - 1
        var maximumRow = height / scl - 1

        var left = 0
        var right = 0
        var up = 0
        var down = 0


        for (let i = colOfSnake - 1; i >= 0; i--) {
            if (newArray[rowOfSnake][i] !== 0) {
                left = colOfSnake - i
                break;
            }
        }
        if (left === 0) {
            for (let i = maximumCol; i >= colOfSnake; i--) {
                if (newArray[rowOfSnake][i] !== 0) {
                    left = (maximumCol - i) + (colOfSnake) + 1
                    break;
                }
            }
        }

        for (let i = colOfSnake + 1; i <= maximumCol; i++) {
            if (newArray[rowOfSnake][i] !== 0) {
                right = i - colOfSnake
                break;
            }
        }
        if (right === 0) {
            for (let i = 0; i <= colOfSnake; i++) {
                if (newArray[rowOfSnake][i] !== 0) {
                    right = (maximumCol - colOfSnake) + i + 1
                    break;
                }
            }
        }

        for (let i = rowOfSnake - 1; i >= 0; i--) {
            if (newArray[i][colOfSnake] !== 0) {
                up = rowOfSnake - i
                break;
            }
        }

        if (up === 0) {
            for (let i = maximumRow; i >= rowOfSnake; i--) {
                if (newArray[i][colOfSnake] !== 0) {
                    up = (maximumRow - i) + (rowOfSnake) + 1
                    break;
                }
            }
        }

        for (let i = rowOfSnake + 1; i <= maximumRow; i++) {
            if (newArray[i][colOfSnake] !== 0) {
                down = i - rowOfSnake
                break;
            }
        }
        if (down === 0) {
            for (let i = 0; i <= rowOfSnake; i++) {
                if (newArray[i][colOfSnake] !== 0) {
                    down = (maximumRow - rowOfSnake) + i + 1
                    break;
                }
            }
        }
        let a = Math.abs(snake.x - food.x);
        let b = Math.min(Math.abs(snake.x + (width - food.x)), Math.abs((width - snake.x) + food.x));
        let c = Math.abs(snake.y - food.y);
        let d = Math.min(Math.abs(snake.y + (height - food.y)), Math.abs((height - snake.y) + food.y));

        let xDiff = Math.min(a, b)
        if (xDiff === a) {

            a = -(snake.x - food.x)

        } else {
            if (snake.x > food.x) {
                a = (((width) - snake.x) + food.x)
            } else {
                a = -(snake.x + ((width) - food.x))
            }
        }
        let yDiff = Math.min(c, d)
        if (yDiff === c) {

            c = -(snake.y - food.y)

        } else {
            if (snake.y > food.y) {
                c = (((height) - snake.y) + food.y)
            } else {
                c = -(snake.y + ((height) - food.y))
            }
        }

        const state: any[] = [a, c, up, down, left, right, snake.x - rival.x, snake.y - rival.y];

        return state;
    }


    function mapArray() {
        var s1tailArr = s1.tailLocation();
        var s2tailArr = s2.tailLocation();
        var outputSnakeArr = []

        for (let i = 0; i < (height / scl) * (width / scl); i++) {
            outputSnakeArr[i] = 0
        }

        for (let i = 0; i < s1tailArr.length; i++) {
            outputSnakeArr[Math.floor(s1tailArr[i].x / scl) + Math.floor(s1tailArr[i].y / scl) * (height / scl)] = 1
        }

        outputSnakeArr[Math.floor(s1.x / scl) + Math.floor(s1.y / scl) * (height / scl)] = 2

        for (let i = 0; i < s2tailArr.length; i++) {
            outputSnakeArr[Math.floor(s2tailArr[i].x / scl) + Math.floor(s2tailArr[i].y / scl) * (height / scl)] = -1
        }

        outputSnakeArr[Math.floor(s2.x / scl) + Math.floor(s2.y / scl) * (height / scl)] = -2

        return outputSnakeArr;
    }

    function step(snake: Snake, action: number) {
        if (action === 0 && snake.direction !== "LEFT") {
            snake.dir(1, 0);
            snake.direction = "RIGHT";
        } else if (action === 1 && snake.direction !== "RIGHT") {
            snake.dir(-1, 0);
            snake.direction = "LEFT";

        } else if (action === 2 && snake.direction !== "UP") {
            snake.dir(0, 1);
            snake.direction = "DOWN";

        } else if (action === 3 && snake.direction !== "DOWN") {
            snake.dir(0, -1);
            snake.direction = "UP";

        }
    }


    function getRewardAndDone(snake: Snake) {
        // var reward = -0.3
        var reward = 0

        if (snake.eat(food)) {
            if (snake === s1) {
                s1Eat++
            }
            reward += 25
            setEatFood(true)
        }
        if (snake.closer) {
            reward += 0.3
        }
        if (!snake.closer) {
            reward -= 0.15
        }
        if (snake.done) {
            reward -= 50
        }


        var prevent;
        if (snake.tail.length !== 0) {
            if (snake.receivedAction === 0) {
                prevent = "LEFT"
            } else if (snake.receivedAction === 1) {
                prevent = "RIGHT"
            } else if (snake.receivedAction === 2) {
                prevent = "UP"
            } else {
                prevent = "DOWN"
            }
            if (snake.direction === prevent) {
                reward -= 1
            }
        }
        snake.score += reward

        return [reward, snake.done]
    }

    async function reset() {
        s1 = null
        s2 = null
        food = null
        s1Eat = 0
        s2Eat = 0

        setEatFood(false);
        setEatTotal(0);

        s1 = new Snake(0, 0, 1, 0, 115, 73, 172, "RIGHT")
        s2 = new Snake(height - scl, height - scl, -1, 0, 135, 13, 16, "LEFT")

        pickLocation();

        await firstTimeChooseAction();
    }


    // to backend

    async function firstTimeChooseAction() {
        let observation = getState(s1);
        const s1fetchRes = await fetch(`${process.env.REACT_APP_BACKEND_URL}/s1firstTimeChooseAction`, {

            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({ state: observation }),
        })
        const s1result = await s1fetchRes.text()
        step(s1, parseInt(JSON.parse(s1result).action))

        firstTime = true;

    }

    async function chooseAction() {

        //s1 state
        let rewardAndDone = getRewardAndDone(s1)
        if (eatFood) {
            pickLocation();
            setEatFood(false)
            setEatTotal(eatTotal + 1)
        }
        let observation = getState(s1);
        observation = [observation, rewardAndDone[0], rewardAndDone[1]]

        //s1 request action
        const s1fetchRes = await fetch(`${process.env.REACT_APP_BACKEND_URL}/s1chooseAction`, {

            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({ state: observation }),
        })
        const s1result = await s1fetchRes.text();

        // front end

        if (s1Eat >= 20) {
            s2.done = true
        }
        if (s2Eat >= 20) {
            s1.done = true
        }

        if (s1.done || s2.done) {
            if (s1.done && s2.done) {
                winner = 'No';
                evened++;
            } else if (s1.done) {
                winner = 'Player';
                playerWin++
            } else if (s2.done) {
                winner = 'AI';
                AiWin++
            }
            round++
            setGameEnd(true)
            setStart(false)
        }

        step(s1, parseInt(JSON.parse(s1result).action))
        s1.receivedAction = parseInt(JSON.parse(s1result).action);
    }


    document.addEventListener('keydown', async (event) => {
        if (!press) {
            const keyName = event.key;
            if (keyName === 'ArrowUp') {
                step(s2, 3)
            }
            if (keyName === 'ArrowDown') {
                step(s2, 2)
            }
            if (keyName === 'ArrowRight') {
                step(s2, 0)
            }
            if (keyName === 'ArrowLeft') {
                step(s2, 1)
            }
            press = true
        }
    });


    return (
        <>
            <div className="background">
                <Container className="vsAi-container-background">
                    <Row>
                        <Col sm="6">
                            <div className="game-container">
                                <div className="vsAi-game">
                                    <Sketch setup={setup} draw={draw} />
                                </div>
                            </div>

                            {
                                start ?
                                    <Button className="vsAi-button" color="secondary" disabled>restart</Button>
                                    :
                                    <Button onClick={() => {
                                        if (gameEnd) {
                                            setStart(true)
                                            reset();
                                            if (first) {
                                                setFirst(false)
                                            }
                                        }
                                    }} className="vsAi-button" color="secondary">{first ? 'start' : 'restart'}</Button>
                            }


                        </Col>
                        <Col sm="6">
                            <h1>𝒮𝓃𝒶𝓀𝑒 𝒜𝐼</h1>
                            <h1>𝐵𝒶𝓉𝓉𝓁𝑒</h1>
                            <h5 className="vsAI-progress-bar-text">𝓐𝓘:</h5>
                            <Progress value={s1Eat * 100 / 20} className="vsAI-s1-progress-bar" color="info" />
                            <h5 className="vsAI-progress-bar-text">𝓟𝓵𝓪𝔂𝓮𝓻:</h5>
                            <Progress value={s2Eat * 100 / 20} className="vsAI-s2-progress-bar" color="danger" />
                        </Col>
                    </Row>
                    <h4 className="count-text">𝓦𝓲𝓷𝓷𝓮𝓻: {winner}</h4>
                    <h4 className="count-text">𝓻𝓸𝓾𝓷𝓭: {round}</h4>
                    <h4 className="count-text">𝙋𝙡𝙖𝙮𝙚𝙧 𝙬𝙞𝙣: {playerWin}</h4>
                    <h4 className="count-text">𝘼𝙄 𝙬𝙞𝙣: {AiWin}</h4>
                    <h4 className="count-text">𝙚𝙫𝙚𝙣: {evened}</h4>
                </Container>
            </div>
        </>
    )
}

export default VsAiPage;