import { User, Message } from "../../types";
import { ThunkDispatch } from "../../store";




export function loginSuccess(token: string) {
  return {
    type: "loginSuccess" as "loginSuccess",
    token,
  };
}
export function searchUser(text: string, token :string) {
  console.log("hello")
  return  async function  (dispatch: ThunkDispatch){
    console.log("hi")
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({text})
    })

    if (res.status !== 200) {
      console.error("failed to search users")
      return
    }

    const json = await res.json();
    console.log(json)

    dispatch(saveSearchResult(json))
  }
}


export function saveSearchResult(result: User[]) {    
  
  return {
    type: "saveSearchResult" as "saveSearchResult",
    result,
  };
}


export function createChatroom(user_id:number) { // loadmessage  change to
 
 
  return {
    type: "createChatroom" as "createChatroom",
    user_id

  };
}
export function addChatMessage (room_id :number, messages:Message[]){
  return{
    type: "addChatMessage" as "addChatMessage",
    messages,
    room_id
  }

}




export function closeChatroom(user_id: number) {
  return {
    type: "closeChatroom" as "closeChatroom",
    user_id
  };
}



export type chatRoomAction =
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof addChatMessage>
  // | ReturnType<typeof searchUser>
  | ReturnType<typeof saveSearchResult>
  | ReturnType<typeof createChatroom>
  | ReturnType<typeof closeChatroom>
  ;


// export async function submit() {
//   // console.log(formState.values)
//   return async (dispatch: ThunkDispatch) => {
//     const formData = new FormData();

//     const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',

//         // 'Authorization': `Bearer ${token}`
//       },
//       body: JSON.stringify(formData.values)
//     })

//     if (res.status != 200) {
//       console.error("failed to search users")
//       return
//     }

//     const json = await res.json();
//     console.log(json)
//     dispatch(searchUser(json))
//   }
// }


// socket.on('chat', function (data: any) {
//   console.log(data.message)
//   console.log(data.created_at)
//   messages.push(data.message)
// })