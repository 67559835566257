import React, { useEffect } from "react";
// import { Chatroom as Room } from "../../types"
import "./chatroom.css";
import { CloseBtn } from "../close-btn/close-btn";
import { useDispatch, useSelector } from "react-redux";
import { closeChatroom, addChatMessage } from "../../redux/chatBox/action";
// import { closeChatroom } from "../../redux/chatBox/action";
import { useState } from "react";
import { socket } from "../../socket";
import { RootState } from "../../store";
import { addFriend, removeFriend } from "../../redux/friends/action";
// import { Message } from "../../types";

// <AddBtn add={props.add} />

export function Chatroom(prop: { user_id: number }) {
  //where the user_id comes from

  let dispatch = useDispatch();
  let [messageText, setmessageText] = useState("");
  let [username, setUserName] = useState("");
  let [isFriend, setIsFriend] = useState(false);

  //  const room = useSelector((state: RootState) => state.chatroom.rooms.find(room=>room.user_id===prop.user_id));
  //  console.log("chatRoom", room)
   const messages= useSelector((state: RootState) => state.chatroom.rooms.find(room=>room.user_id===prop.user_id)?.messages);       // please notice the depth of the store , you must select the exact source for useselector
   console.log("messages",messages)

  // let [messages, setMessages] = useState([] as Message[]);

  let { token } = useSelector((state: RootState) => state.auth);

  // let { rooms } = useSelector((state: RootState) => state.chatroom);
  function close() {
    dispatch(closeChatroom(prop.user_id));
  }

  function send() {
    console.log("send");
    socket.emit("chat", { message: messageText, receiver: prop.user_id });
    dispatch(addChatMessage(prop.user_id, [{
      created_at: new Date().toLocaleDateString(),
      message: messageText,
      receiver: prop.user_id
    }]

    ))
    // setMessages([
    //   ...messages,
    //   {
    //     created_at: new Date().toLocaleDateString(),
    //     message: messageText,
    //     receiver: prop.user_id,
    //   },
    // ]);

    setmessageText("");
  }

  async function getMessageAndName() {
    //activly get infor from backend

    let res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/message/room/${prop.user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let json = await res.json();
    console.log("json from server", json);

    // setIsFriend(json.friend)   // shows the result

    setUserName(json.userName);
    // setMessages(json.messages);
    dispatch(addChatMessage(prop.user_id, json.messages))      // add
  }

  useEffect(
    () => {
      getMessageAndName();
    }, // retrieve userName with userID
    // eslint-disable-next-line
    []
  ); // this will be read everytime

  if ( !messages) {
    return <div>Loading</div> };

  async function friend(method: string) {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/friends/${prop.user_id}`,
      {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res);

    setIsFriend(method === "post");

    let user_id = prop.user_id;

    if (method === "post") {
      dispatch(addFriend(user_id, username));
    }
    if (method === "delete") {
      dispatch(removeFriend(user_id));
    }
    // === return a boolean
  }

  // useEffect (()=>{

  //   },[isFriend])

  return (
    <div className="chatroom">
      <div className="app-row  header">
        {isFriend ? (
          <button onClick={() => friend("delete")}>unFriend</button> // isfriend hasnt come back ,
        ) : (
          <button onClick={() => friend("post")}>add</button>
        )}

        <div className="grow">
          Chat with {username}({messages.length})
          {/*           
          1:{messages.map(message=><div>
            {message.message}
          </div>)}


          2:{messages.map(message=>message.message).join(',')}

          3:{messages.map(message=>message.message)} */}
        </div>
        <CloseBtn close={close} />
      </div>
      <div className="content">
        {/* 1:
        {messages.map((message) => (
          <div>{message.message}</div>
        ))}
        ----------------------------------- 
        1.2:
        {messages.map((message) => (
          <div>
              <div>
              {message.message}
              </div>           
            </div>
        ))}
           ----------------------------------- 
        1.3:
        {messages.map((message) => (
          <div>
              <div>
              {message.message}
              </div>     
              <div>
                {message.created_at}
                </div>      
            </div>
        ))}
        ----------------------------------- 
        2:
        {messages.map((message) => message.message).join(",")}
        ----------------------------------- 
        3:
        {messages.map((
          message,
          i // default index for message
        ) => (
          <div>
            {message.receiver === prop.user_id ? "me" : username}

            
            <div className="app-row  ">
              <p className="grow">{message.message}</p>
            
            </div>
          </div>
        ))}
        -----------------------------------
         4:
        {messages.map((
          message,
          i // default index for message
        ) => (
          <div>{message.receiver === prop.user_id ? "me" : username}</div>
        ))}
        ----------------------------------
         5:
        {messages.map((
          message,
          i // default index for message
        ) => (
          <div>
            <div className="app-row  ">
              <p className="grow">{message.message}</p>
            </div>
          </div>
        ))}
        ---------------------------------- */}
        {messages.map((
          message,
          i // default index for message
        ) => (
          <span key={i}>
            {message.receiver === prop.user_id ? "me" : username}

            {/* { <span style={{float:'left'}}></span>:<span style={{float:'right'}}></span> } */}
            <div className="app-row  ">
              <p className="grow">{message.message}</p>
              {/* <div>{message.created_at}</div> */}
              <div>{new Date( message.created_at).toLocaleDateString()}</div>
            </div>
          </span>
        ))}
      </div>
      <div className="inputs row">
        <input
          className="grow"
          value={messageText}
          onChange={(e) => setmessageText((e.target as HTMLInputElement).value)}
          placeholder="search by username"
        />
        <button onClick={send}>Send</button>
      </div>
    </div>
  );
}
