export type Message = {
    created_at: string;
    message: string;
    receiver: number;
    
  };
  export type Chatroom = {
    user_id: number;
    // name: string;
    messages: Message[];
    // input:string
  };
  // search result
  export type User = {
    user_id: number;
    name: string;
  };
  export type chatRoomState = {
    token: string;
    search_result: User[];
    rooms: Chatroom[];
  };
  export let initState: chatRoomState = {
    token: '',
    search_result: [],
    rooms:[],
  }

  ///